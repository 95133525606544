body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pattern {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: radial-gradient(#444cf7 0.8px, transparent 0.8px),
    radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  background-size: 32px 32px;
  background-position: 0 0, 16px 16px;
}
.patternred {
  background-color: #fff;
  opacity: 0.8;
  background-image: radial-gradient(#ff0000 0.5px, transparent 0.5px),
    radial-gradient(#ff0000 0.5px, #fff 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}
.patterngray {
  background-color: #fff;
  opacity: 0.8;
  background-image: radial-gradient(#555555 0.8px, transparent 0.8px),
    radial-gradient(#555555 0.8px, #fff 0.8px);
  background-size: 32px 32px;
  background-position: 0 0, 16px 16px;
}
.patternblue {
  background-color: #fff;
  opacity: 0.8;
  background-image: radial-gradient(
      #587ab8 1.1500000000000001px,
      transparent 1.1500000000000001px
    ),
    radial-gradient(#587ab8 1.1500000000000001px, #fff 1.1500000000000001px);
  background-size: 46px 46px;
  background-position: 0 0, 23px 23px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
